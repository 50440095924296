.item-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20px;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 900px;
    row-gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (max-width: 700px) {
    .item-list {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
}