.banner {
    height: calc( 400 * 100vw / 1920);
    position: relative;
    background: #eee;
    overflow: hidden;
    width: 100%;
}

.banner-contents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
    position: absolute;
}

.banner-image-container {
    width: 100vw;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.banner-image {
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-image.mobile {
    display: none;
}

.banner-image.wide {
    display: block;
}

.banner-button {
    position: absolute;
    background: rgba(150,150,150,0.3);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    top: calc(50% - 25px);
    display: none;
    z-index: 2;
}

.banner-button.left {
    left: 5%;
}

.banner-button.right {
    right: 5%;
}

.banner:hover .banner-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.banner-counter {
    position: absolute;
    bottom: 5%;
    right: 10%;
    padding: 4px 12px 4px 12px;
    background: rgba(150,150,150,0.3);
    border-radius: 50px;
    z-index: 1;
}

.banner-counter-number {
    font-weight: 500;
    color: #fff;
}

.bannner-button-icon {
    width: 34px;
    height: 34px;
}

@media (max-width: 1440px) {
    .banner {
        height: calc( 400 * 130vw / 1920);
    }
}

@media (max-width: 700px) {
    .banner {
        height: 350px;
    }
    .banner-image {
        top: 50%;
        left: 0%;
        transform: translate(0%, -50%);
    }
    .banner-image.wide {
        display: none;
    }
    .banner-image.mobile {
        display: block;
    }
    .banner:hover .banner-button {
        display: none;
    }
}