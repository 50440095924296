.order-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.order-description-titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 6px 6px 10px;
}

.order-description-title {
    color: #777;
}

.order-description-update-button-text {
    color: #EA5B30;
}

.order-description-contents {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.order-description-contents-text {
    color: #888;
    display: block;
    white-space: pre;
}