.order {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #eee;
    padding-top: 20px;
    padding-bottom: 20px;
    row-gap: 16px;
}

.order-id-container {
    padding: 10px 12px 10px 12px;
    background: rgb(235, 92, 46);
    border-radius: 8px;
}

.order-id {
    color : #fff;
}

.order-contents-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.order-contents-container.center {
    justify-content: center;
}

.order-contents-container.right {
    justify-content: flex-end;
}

.order-contents-container.space {
    justify-content: space-between;
}

.order-contents-container.top-down {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 6px;
}

.order-contents-container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.order-manager-button {
    padding: 3 3 3 3;
}

.order-manager-button-text {
    color: #999;
}

.order-contents {
    color: #333;
}

.order-items {
    color: #666;
}

.order-items.warning {
    color: red;
    font-weight: 500;
}

.order-items-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    row-gap: 6px;   
}

.order-status-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.order-status-button {
    flex: 1;
    border-radius: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.order-status-button.on {
    border: 1px solid rgb(235, 92, 46);
    background:rgb(235, 92, 46);
}

.order-status-button.off {
    border: 1px solid rgb(235, 92, 46);
    background-color: rgba(0,0,0,0);
}

.order-status-button.deactivated {
    border: 1px solid #c8c8c8;
    background-color: rgba(0,0,0,0);
}

.order-status-button-text.on {
    color: #fff;
}

.order-status-button-text.off {
    color: rgb(235, 92, 46);
}

.order-status-button-text.deactivated {
    color: #c8c8c8;
}

.order-canceled-notification-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.order-canceled-notification {
    color: rgb(235, 92, 46);
}


.order-pay-method-buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    column-gap: 6px;
}

.order-pay-method-button {
    padding: 3px 6px 3px 6px;
    border: 1px solid #777;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.order-pay-method-button.on {
    border: 1px solid rgb(235, 92, 46);
    background:rgb(235, 92, 46);
}

.order-pay-method-button.off {
    border: 1px solid rgb(235, 92, 46);
    background-color: rgba(0,0,0,0);
}

.order-pay-method-button-text.on {
    color: #fff;
}

.order-pay-method-button-text.off {
    color: rgb(235, 92, 46);
}